<template>
    <MainContainer>
        <div class="exams-deadlines">
            <h2>Fristen</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="8">
                            <h6>Prüfungsarten</h6>
                            <CheckboxSelect
                                :options="typeSelect"
                                v-model="filter.type"
                                :size="size"
                            />
                        </b-col>
                        <b-col cols="4">
                            <h6>Fristen gültig ab</h6>
                            <b-form-group>
                                <b-form-radio-group
                                    v-model="currentDateSelection"
                                    :options="currentDateOptions"
                                ></b-form-radio-group>
                            </b-form-group>
                            <b-form-group>
                                <b-input
                                    type="date"
                                    :disabled="currentDateSelection == 'all'"
                                    v-model="currentDateValue"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                            <b-button
                                class="ml-1"
                                :size="size"
                                @click="resetFilter"
                                variant="danger"
                                >Filter zurücksetzen</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>

                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="openModificationModal({ mode: 'create' })"
                        >Neue Frist</b-button
                    >
                    <b-button
                        class="mx-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="records"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="deadline_date"
                :sort-desc="true"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">
                                Gesamt: {{ records.length }}
                            </p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(type)="data">
                    {{ typeName(data.value) }}
                </template>

                <template #cell(deadline_date)="data">
                    {{ formatDate(data.value) }}
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <EditModal
                :busy="busy.mutation"
                :mode="modificationMode"
                size="xl"
                @ok="handleModification"
            >
                <FormGroupBuilder
                    :fields="ioFields"
                    :data="modificationRecord"
                />
            </EditModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Date from "@/mixins/Date/Date";

import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ExamsDeadlines",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        EditModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size, Date],
    data() {
        return {
            filter: {
                pattern: null,
                vhs: []
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "type",
                    label: "Prüfungsart",
                    sortable: true
                },
                {
                    key: "deadline_title",
                    label: "Fristbeschreibung",
                    sortable: true
                },
                {
                    key: "deadline_type",
                    label: "Fristtyp",
                    sortable: true
                },
                {
                    key: "deadline_date",
                    label: "Gültig ab",
                    sortable: true
                },
                {
                    key: "deadline_duration",
                    label: "Gültigkeitsbereich"
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                type: {
                    key: "type",
                    label: "Prüfungsart",
                    type: "select",
                    options: [{ value: null, text: "Keine Auswahl" }]
                },
                deadline_title: {
                    key: "deadline_title",
                    label: "Fristbeschreibung"
                },
                deadline_type: {
                    key: "deadline_type",
                    label: "Fristtyp"
                },
                deadline_date: {
                    key: "deadline_date",
                    label: "Gültig ab",
                    type: "date"
                },
                deadline_duration: {
                    key: "deadline_duration",
                    label: "Gültigkeitsbereich",
                    description: "Angabe in Tagen vor Prüfungsende",
                    placeholder: "5"
                }
            },
            selectedFields: [
                "id",
                "type",
                "deadline_title",
                "deadline_type",
                "deadline_date",
                "deadline_duration"
            ],
            actions: {
                create: "examsDeadlines/createData",
                edit: "examsDeadlines/editData",
                delete: "examsDeadlines/deleteData",
                deleteMulti: "examsDeadlines/deleteMultipleData"
            },
            currentDateOptions: [
                {
                    text: "Alle",
                    value: "all"
                },
                { text: "Ab bestimmtem Datum", value: "current" }
            ],
            currentDateSelection: "all",
            currentDateValue: null
        };
    },
    computed: {
        ...mapGetters({
            busy: "examsDeadlines/getBusy",
            records: "examsDeadlines/getData",
            typeName: "examsTypes/getTypeName",
            typeSelect: "examsTypes/getSelectData"
        }),
        dataAvailable() {
            return this.records.length > 0;
        },
        typesIoSelect() {
            const types = this.typeSelect;
            types.unshift({ value: null, text: "Keine Auswahl" });
            return types;
        }
    },
    created() {
        this.fetch();
        this.fetchTypes();
    },
    watch: {
        typesIoSelect: {
            handler(val) {
                this.ioFields.type.options = val;
            }
        }
    },
    methods: {
        ...mapActions({
            fetchAll: "examsDeadlines/fetchData",
            fetchCurrent: "examsDeadlines/fetchCurrentDeadlines",
            fetchTypes: "examsTypes/fetchData"
        }),
        fetch() {
            if (this.currentDateSelection == "current") {
                this.fetchCurrent({
                    date: this.currentDateValue,
                    filter: this.filter
                });
            } else {
                this.fetchAll({ filter: this.filter });
            }
        },
        applyFilter() {
            this.fetch();
        },
        resetFilter() {
            this.filter = { pattern: null, vhs: [] };
            this.currentDateSelection = "all";
            this.fetch();
        }
    }
};
</script>
